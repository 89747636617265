<template>
<div></div>
</template>

<script>

import mgr from "@/libs/oidc.js";
export default {
  beforeCreate () {
    mgr().signinRedirect()
  }
}
</script>